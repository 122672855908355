<template>
  <v-container class="pa-0 container_order_list">
    <v-expansion-panels
      v-model="panel"
      accordion
      multiple
      flat
    >
      <v-expansion-panel key="1">
        <v-expansion-panel-header>
          <div class="w-100">
            <v-row>
              <v-col cols="auto pr-0 d-flex align-center">
                <v-icon>mdi-table-clock</v-icon>
              </v-col>
              <v-col cols="auto">
                <v-badge
                  :content="scheduledOrderItems.length"
                  :value="scheduledOrderItems.length"
                  color="orange"
                  overlap
                  class="d-block"
                  offset-x="5"
                  offset-y="20"
                >
                  <p class="ma-0 text-subtitle-2 font-weight-black">{{$_strings.schedule.label.SCHEDULED_ORDER}}</p>
                </v-badge>
                <span class="caption grey--text">{{$_strings.schedule.label.LIST_SCHEDULED_ORDER}}</span>
              </v-col>
              <v-col cols="auto">
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row justify="center" v-if="!scheduledOrderItems.length">
            <h6>{{$_strings.schedule.label.NO_DATA}}</h6>
          </v-row>
          <v-row v-else v-for="(order, index) in scheduledOrderItems" :key="index">
            <v-col cols="8">
              <span class="caption">{{order.date}}</span>
              <h6>{{order.cargoName}} - {{order.shipmentGroupTitle}}</h6>
              <span class="caption">{{order.driverName}}</span>
            </v-col>
            <v-col class="d-flex justify-end">
              <span class="caption">{{order.time}}</span>
            </v-col>
            <v-col v-if="index + 1 !== scheduledOrderItems.length" cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
        <v-divider></v-divider>
      </v-expansion-panel>
      <v-expansion-panel class="catskill_white" key="2">
        <v-expansion-panel-header>
          <div class="w-100">
            <v-row>
              <v-col cols="auto pr-0 d-flex align-center">
                <v-icon>mdi-cube</v-icon>
              </v-col>
              <v-col cols="auto">
                <v-badge
                  :content="orderOnProcessItems.length"
                  :value="orderOnProcessItems.length"
                  color="blue"
                  overlap
                  offset-x="5"
                  offset-y="20"
                  class="d-block"
                >
                  <p class="ma-0 text-subtitle-2 font-weight-black">{{$_strings.schedule.label.ON_PROCESS}}</p>
                </v-badge>
                <span class="caption grey--text">{{$_strings.schedule.label.LIST_ON_PROCESS}}</span>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row justify="center" v-if="!orderOnProcessItems.length">
            <h6>{{$_strings.schedule.label.NO_DATA}}</h6>
          </v-row>
          <v-row v-else v-for="(order, index) in orderOnProcessItems" :key="index">
            <v-col cols="8">
              <span class="caption">{{order.date}}</span>
              <h6>{{order.cargoName}} - {{order.shipmentGroupTitle}}</h6>
              <span class="caption">{{order.driverName}}</span>
            </v-col>
            <v-col class="d-flex justify-end">
              <span class="caption">{{order.time}}</span>
            </v-col>
            <v-col v-if="index + 1 !== orderOnProcessItems.length" cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
        <v-divider></v-divider>
      </v-expansion-panel>
      <v-expansion-panel class="catskill_white" key="3">
        <v-expansion-panel-header>
          <div class="w-100">
            <v-row>
              <v-col cols="auto pr-0 d-flex align-center">
                <v-icon>mdi-briefcase-check</v-icon>
              </v-col>
              <v-col cols="auto">
                <v-badge
                  :content="orderCompletedItems.length"
                  :value="orderCompletedItems.length"
                  color="green"
                  overlap
                  class="d-block"
                  offset-x="5"
                  offset-y="20"
                >
                  <p class="ma-0 pa-0 text-subtitle-2 font-weight-black">{{$_strings.schedule.label.ORDER_COMPLETED}}</p>
                </v-badge>
                <span class="caption grey--text">{{$_strings.schedule.label.LIST_ORDER_COMPLETED}}</span>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row justify="center" v-if="!orderCompletedItems.length">
            <h6>{{$_strings.schedule.label.NO_DATA}}</h6>
          </v-row>
          <v-row v-else v-for="(order, index) in orderCompletedItems" :key="index">
            <v-col cols="8">
              <span class="caption">{{order.date}}</span>
              <h6>{{order.cargoName}} - {{order.shipmentGroupTitle}}</h6>
              <span class="caption">{{order.driverName}}</span>
            </v-col>
            <v-col class="d-flex justify-end">
              <span class="caption">{{order.time}}</span>
            </v-col>
            <v-col v-if="index + 1 !== orderCompletedItems.length" cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
        <v-divider></v-divider>
      </v-expansion-panel>
      <v-expansion-panel class="catskill_white" key="4">
        <v-expansion-panel-header>
          <div class="w-100">
            <v-row>
              <v-col cols="auto pr-0 d-flex align-center">
                <v-icon>mdi-package-variant-closed</v-icon>
              </v-col>
              <v-col cols="auto">
                <v-badge
                  :content="orderCancelItems.length"
                  :value="orderCancelItems.length"
                  color="red"
                  overlap
                  class="d-block"
                  offset-x="5"
                  offset-y="20"
                >
                  <p class="ma-0 pa-0 text-subtitle-2 font-weight-black">{{$_strings.schedule.label.ORDER_CANCEL}}</p>
                </v-badge>
                <span class="caption grey--text">{{$_strings.schedule.label.LIST_ORDER_CANCEL}}</span>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row justify="center" v-if="!orderCancelItems.length">
            <h6>{{$_strings.schedule.label.NO_DATA}}</h6>
          </v-row>
          <v-row v-else v-for="(order, index) in orderCancelItems" :key="index">
            <v-col cols="8">
              <span class="caption">{{order.date}}</span>
              <h6>{{order.cargoName}} - {{order.shipmentGroupTitle}}</h6>
              <span class="caption">{{order.driverName}}</span>
            </v-col>
            <v-col class="d-flex justify-end">
              <span class="caption">{{order.time}}</span>
            </v-col>
            <v-col v-if="index + 1 !== orderCancelItems.length" cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
        <v-divider></v-divider>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { dateFormat, timeFormat } from '../../helper/commonHelpers';

export default {
  name: 'order-list',
  props: {
    dataCalender: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    if (this.dataCalender.length) this.setData();
  },
  data() {
    return {
      panel: [],
      scheduledOrderItems: [],
      orderOnProcessItems: [],
      orderCompletedItems: [],
      orderCancelItems: [],
    };
  },
  watch: {
    dataCalender() {
      this.setData();
    },
  },
  methods: {
    setData() {
      const orderOnProcess = this.dataCalender.filter((p) => p.status === 'PROCESS');
      const orderOnSchedule = this.dataCalender.filter((p) => p.status === 'SCHEDULED');
      const orderOnFinish = this.dataCalender.filter((p) => p.status === 'FINISHED');
      const orderOnCancel = this.dataCalender.filter((p) => p.status === 'CANCELLED');
      this.orderOnProcessItems = orderOnProcess.map((res) => ({
        shipmentGroupTitle: res.shipmentGroupTitle,
        cargoName: res.shipmentTitle,
        date: dateFormat(res.pickupDate),
        time: timeFormat(res.pickupDate),
      }));
      this.orderCancelItems = orderOnCancel.map((res) => ({
        shipmentGroupTitle: res.shipmentGroupTitle,
        cargoName: res.shipmentTitle,
        date: dateFormat(res.pickupDate),
        time: timeFormat(res.pickupDate),
      }));
      this.scheduledOrderItems = orderOnSchedule.map((res) => ({
        shipmentGroupTitle: res.shipmentGroupTitle,
        cargoName: res.shipmentTitle,
        date: dateFormat(res.pickupDate),
        time: timeFormat(res.pickupDate),
      }));
      this.orderCompletedItems = orderOnFinish.map((res) => ({
        shipmentGroupTitle: res.shipmentGroupTitle,
        cargoName: res.shipmentTitle,
        date: dateFormat(res.pickupDate),
        time: timeFormat(res.pickupDate),
      }));
    },
  },
};
</script>
<style lang="scss" scoped>
.container::v-deep > .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: #F1F5F8 !important;
}
</style>
