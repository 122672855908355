<template>
  <v-container fluid>
    <!-- filter -->
    <common-error
      v-if="isError"
      @fetchData="fetchCalendar"
    />
    <section v-else-if="isLoading">
      <SkeletonLoader />
    </section>
    <section v-else>
      <v-card elevation="2" class="pa-6">
        <v-row justify="space-between">
          <v-col cols="12" sm="4" md="3">
            <p class="ma-0">{{ $_strings.schedule.label.PARTNER_3PL }}</p>
            <common-auto-complete-items
              type="masterPartner"
              searchName="companyName"
              item-value="id"
              item-text="companyName"
              v-model="filters.transporterId"
              dense
              outlined
              class="caption"
              clearable
              :placeholder="`${$_strings.schedule.label.PARTNER_3PL}`"
              :filter="partner3plFilterItems"
              :items="itemsTransporter"
              @updateItems="updateListTransporter"
            />
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <p class="ma-0">{{ $_strings.schedule.label.CUSTOMER }}</p>
            <common-auto-complete-items
              type="masterShipper"
              searchName="companyName"
              item-value="id"
              item-text="companyName"
              v-model="filters.shipperId"
              dense
              outlined
              class="caption"
              clearable
              :placeholder="`${$_strings.schedule.label.CUSTOMER}`"
              :filter="partnerShipperFilterItems"
              :items="itemsCustomers"
              @updateItems="updateListCustomers"
            />
          </v-col>
          <v-col cols="auto" sm="4" md="6" class="d-flex justify-end align-center">
            <v-btn color="primary" @click="fetchCalendar">
              {{ $_strings.schedule.label.APPLY_FILTER }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <!-- Main -->
      <v-container fluid>
        <v-row>
          <!-- calender -->
          <v-col class="pa-0" cols="12" md="7">
            <v-card tile class="mt-2">
              <Calender
                :dataCalender="dataCalender"
                :filters="filters"
                @fetchCalendar="fetchCalendar"
              />
            </v-card>
          </v-col>
          <!-- sidebar -->
          <v-col
            cols="12"
            md="5"
            class="catskill_white">
            <OrderList
              :dataCalender="dataCalender"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import OrderList from './OrderList.vue';
import Calender from './Calender.vue';
import SkeletonLoader from './SkeletonLoader.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  name: 'schedule',
  components: {
    OrderList,
    Calender,
    SkeletonLoader,
  },
  data() {
    return {
      isCreated: false,
      isLoading: false,
      isError: false,
      dataCalender: [],
      itemsTransporter: [],
      itemsCustomers: [],
      filters: {
        date: this.$route.query.date || dayjs().format('YYYY-MM-DD'),
        transporterId: '',
        shipperId: '',
      },
      // Filter
      partner3plFilterItems: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
      partnerShipperFilterItems: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
      // Loader
      isLoadingListTransporter: false,
      isLoadingListCustomers: false,
    };
  },
  methods: {
    dayjs,
    updateListCustomers(items) {
      this.itemsCustomers = [...this.itemsCustomers, ...items];
    },
    updateListTransporter(items) {
      this.itemsTransporter = [...this.itemsTransporter, ...items];
    },
    toggleLoading(boolean) {
      if (this.isCreated) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.isLoading = boolean;
    },
    async fetchCalendar(event) {
      if (event === 'next') {
        this.filters.date = dayjs(this.filters.date).add(1, 'month').format('YYYY-MM-DD');
      }
      if (event === 'prev') {
        this.filters.date = dayjs(this.filters.date).subtract(1, 'month').format('YYYY-MM-DD');
      }
      if (this.$route.query.date !== this.filters.date) {
        this.$router.replace({
          query: {
            date: this.filters.date,
          },
        });
      }
      try {
        this.toggleLoading(true);
        this.isError = false;
        const res = await this.$_services.schedule.getListCalender(this.filters, source);
        this.dataCalender = res.data;
        this.isCreated = true;
      } catch {
        this.isError = true;
      } finally {
        this.toggleLoading(false);
      }
    },
  },
  created() {
    source = CancelToken.source();
    this.fetchCalendar();
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
};
</script>
<style scoped>
  .v-card {
    box-shadow: none !important;
  }
  .catskill_white {
    background-color: #F1F5F8;
  }
</style>
